import React from "react";
import ChatBot from "react-simple-chatbot";

function Chatbot() {
  return (
    <ChatBot
      enableSmoothScroll={true}
      hideUserAvatar={true}
      floating={true}
      placeholder="Type your Message..."
      floatingStyle={{ animation: "bounce 1s infinite" }}
      bubbleOptionStyle={{ textAlign: "left" }}
      headerTitle={
        <div className="flex items-center gap-5">
          <img src="bot.svg" width={40} alt="Bot" />
          <p>SuppliAide Assistant</p>
        </div>
      }
      steps={[
        {
          id: "1",
          message:
            "Hello! Welcome to Supplia. How can I assist you today? Please choose an option below.",
          trigger: "2",
        },
        {
          id: "2",
          options: [
            { key: "option1", value: 1, label: "About Us", trigger: "3" },
            { key: "option2", value: 2, label: "Services", trigger: "4" },
            { key: "option3", value: 3, label: "Technology", trigger: "5" },
            { key: "option4", value: 4, label: "Careers", trigger: "6" },
            { key: "option5", value: 5, label: "Contact Us", trigger: "7" },
            {
              key: "option6",
              value: 6,
              label: "Other Queries",
              trigger: "8",
            },
            {
              key: "option6",
              value: 33,
              label: "Final Suggestion",
              trigger: "33",
            },
          ],
        },
        {
          id: "3",
          message: "Would you like to know more about Supplia?",
          trigger: "9",
        },
        {
          id: "9",
          options: [
            {
              key: "option1",
              value: 7,
              label: "What does Supplia do?",
              trigger: "10",
            },
            {
              key: "option2",
              value: 8,
              label: "What industries do you serve?",
              trigger: "11",
            },
            {
              key: "option3",
              value: 9,
              label: "What's your mission and vision?",
              trigger: "12",
            },
            {
              key: "option4",
              value: 10,
              label: "How can I get involved?",
              trigger: "13",
            },
            {
              key: "option5",
              value: 11,
              label: "Go Back",
              trigger: "2",
            },
          ],
        },
        {
          id: "10",
          message:
            "Supplia is focused on revolutionizing supply chain management with AI-driven solutions and seamless automation. We provide businesses the tools to optimize operations and improve visibility across all stages of the supply chain.",
          trigger: "9",
        },
        {
          id: "11",
          message:
            "We cater to a variety of industries, including logistics, manufacturing, retail, and agriculture, with tailored solutions that streamline and enhance supply chain management.",
          trigger: "9",
        },
        {
          id: "12",
          message:
            "Our mission is to drive innovation and efficiency within the supply chain industry through smart, scalable technologies. Our vision is to build future-ready, sustainable supply chains across Europe and beyond.",
          trigger: "9",
        },
        {
          id: "13",
          message:
            "We are currently building our team and seeking innovative minds! You can explore our open positions by contacting us at careers@hellolyde.eu for more information.",
          trigger: "9",
        },

        {
          id: "4",
          message: "Which service would you like to learn more about?",
          trigger: "14",
        },
        {
          id: "14",
          options: [
            {
              key: "option1",
              value: 12,
              label: "Supply Chain Optimization",
              trigger: "15",
            },
            {
              key: "option2",
              value: 13,
              label: "Automation & AI Solutions",
              trigger: "16",
            },
            {
              key: "option3",
              value: 14,
              label: "Compliance & Security",
              trigger: "17",
            },
            { key: "option4", value: 15, label: "Other", trigger: "18" },
            { key: "option6", value: 17, label: "Go Back", trigger: "2" },
          ],
        },
        {
          id: "15",
          message:
            "Our platform enables businesses to optimize their supply chain operations, offering tools to improve efficiency and reduce costs at every stage of the process.",
          trigger: "14",
        },
        {
          id: "16",
          message:
            "We leverage AI and machine learning to automate key processes, driving faster decisionmaking and greater visibility into supply chain performance.",
          trigger: "14",
        },
        {
          id: "17",
          message:
            "Our platform helps businesses maintain compliance with European regulations like GDPR, while ensuring data security at every level.",
          trigger: "14",
        },
        {
          id: "18",
          message:
            "If you have more specific needs or queries, feel free to reach out to us at contact@supplia.fr for a more personalized response.",
          trigger: "14",
        },

        {
          id: "5",
          message:
            "Our technology is at the heart of our innovation. What would you like to know?",
          trigger: "19",
        },
        {
          id: "19",
          options: [
            {
              key: "option1",
              value: 18,
              label: "What technology powers your platform?",
              trigger: "20",
            },
            {
              key: "option2",
              value: 19,
              label: "How do you use AI in your solutions?",
              trigger: "21",
            },
            {
              key: "option3",
              value: 20,
              label: "Do you offer API integration?",
              trigger: "22",
            },
            { key: "option4", value: 21, label: "Other", trigger: "23" },
            { key: "option6", value: 23, label: "Go Back", trigger: "2" },
          ],
        },
        {
          id: "20",
          message:
            "Our platform is built on a robust cloud infrastructure, using the latest in AI, machine learning, and automation technologies to optimize and streamline supply chains.",
          trigger: "19",
        },
        {
          id: "21",
          message:
            "We integrate AI for predictive analytics, automation of processes, and providing real-time insights, making it easier for businesses to make data-driven decisions.",
          trigger: "19",
        },
        {
          id: "22",
          message:
            "Yes, our platform provides seamless API integration, enabling you to connect legacy systems with modern supply chain tools effortlessly.",
          trigger: "19",
        },
        {
          id: "23",
          message:
            "For more information on how our technology can be tailored to your needs, please reach out at contact@supplia.fr.",
          trigger: "19",
        },

        {
          id: "6",
          message:
            "Are you interested in joining Supplia? We are always looking for passionate talent.",
          trigger: "24",
        },
        {
          id: "24",
          options: [
            {
              key: "option1",
              value: 24,
              label: "What positions are available?",
              trigger: "34",
            },
            {
              key: "option2",
              value: 25,
              label: "How do I apply?",
              trigger: "35",
            },
            {
              key: "option3",
              value: 26,
              label: "What is the company culture like?",
              trigger: "36",
            },
            { key: "option4", value: 27, label: "Other", trigger: "37" },
            { key: "option6", value: 28, label: "Go Back", trigger: "2" },
          ],
        },
        {
          id: "34",
          message:
            "We are currently hiring for various roles in software engineering, AI/ML, and more. Drop us an email at careers@helolyde.eu.",
          trigger: "24",
        },
        {
          id: "35",
          message:
            "You can apply by sending your resume and cover letter to careers@supplia.org. Make sure to highlight how you can contribute to transforming the future of supply chains!",
          trigger: "24",
        },
        {
          id: "36",
          message:
            "At Supplia, we foster innovation, collaboration, and growth. Our culture is centered on solving complex problems with creative solutions, and we value teamwork and initiative.",
          trigger: "24",
        },
        {
          id: "37",
          message:
            "For any other questions about careers at Supplia, feel free to reach out to us directly at careers@supplia.org.",
          trigger: "24",
        },

        {
          id: "7",
          message:
            "Would you like to get in touch? You can reach us through the following options:",
          trigger: "25",
        },
        {
          id: "25",
          options: [
            {
              key: "option1",
              value: 29,
              label: "General Inquiry",
              trigger: "26",
            },
            {
              key: "option2",
              value: 30,
              label: "Sales Inquiry",
              trigger: "27",
            },
            {
              key: "option3",
              value: 31,
              label: "Media/Press Inquiry",
              trigger: "28",
            },
            { key: "option6", value: 32, label: "Go Back", trigger: "2" },
          ],
        },
        {
          id: "26",
          message:
            "For general questions or concerns, please email us at contact@supplia.fr.",
          trigger: "25",
        },
        {
          id: "27",
          message:
            "Interested in our solutions? Contact our sales team at sales@supplia.eu for more information.",
          trigger: "25",
        },
        {
          id: "28",
          message:
            "For media or press-related inquiries, reach us at contact@supplia.fr.",
          trigger: "25",
        },

        {
          id: "8",
          message:
            "How else can I assist you? Please choose from the following options or type your query.",
          trigger: "38",
        },
        {
          id: "29",
          options: [
            {
              key: "option1",
              value: 30,
              label: "General Inquiry",
              trigger: "30",
            },
            {
              key: "option2",
              value: 31,
              label: "Sales Inquiry",
              trigger: "31",
            },
            {
              key: "option3",
              value: 32,
              label: "Media/Press Inquiry",
              trigger: "32",
            },
            { key: "option6", value: 33, label: "Go Back", trigger: "2" },
          ],
        },
        {
          id: "38",
          options: [
            {
              key: "option1",
              value: 30,
              label: "I have a different question",
              trigger: "39",
            },
            {
              key: "option2",
              value: 31,
              label: "I want to know more about Supplia",
              trigger: "40",
            },
            {
              key: "option3",
              value: 32,
              label: "Can I join the waiting list?",
              trigger: "41",
            },
            { key: "option6", value: 33, label: "Go Back", trigger: "2" },
          ],
        },
        {
          id: "30",
          message:
            "Please feel free to email your query to contact@supplia.fr, and we will respond as soon as possible.",
          trigger: "29",
        },
        {
          id: "31",
          message:
            "We would love to share more! Join our waiting list to stay updated. Contact us at contact@supplia.fr for more details.",
          trigger: "29",
        },
        {
          id: "32",
          message:
            "Yes, we are excited to have you! Please send an email to contact@supplia.fr to be added to our waiting list.",
          trigger: "29",
        },
        {
          id: "33",
          message:
            "Thank you for your interest in Supplia. For further inquiries or detailed discussions, feel free to contact us at contact@supplia.fr. We look forward to hearing from you!",
          end: true,
        },
        {
          id: "39",
          message:
            "Please feel free to email your query to contact@supplia.fr, and we will respond as soon as possible.",
          trigger: "38",
        },
        {
          id: "40",
          message:
            "We would love to share more! Join our waiting list to stay updated. Contact us at contact@supplia.fr for more details.",
          trigger: "38",
        },
        {
          id: "41",
          message:
            "Yes, we are excited to have you! Please send an email to contact@supplia.fr to be added to our waiting list.",
          trigger: "38",
        },
      ]}
    />
  );
}

export default Chatbot;
