import React, { useEffect } from "react";
import { Navbar } from "../Navbar";
import Footer from "../Footer";
import BackToTop from "../Utils/BackToTop";

const BasicLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="space-y-32">
        <Navbar background={"bg-[#34495e]"} />
        <div className="max-w-[1000px] mx-auto">{children}</div>
        <Footer />
      </div>
      <BackToTop />
    </>
  );
};

export default BasicLayout;
