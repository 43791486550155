import React from "react";
import { FaTint } from "react-icons/fa";
import { FaFlaskVial, FaGears, FaTractor, FaTruckFast } from "react-icons/fa6";
import { GiAnchor, GiCargoShip } from "react-icons/gi";
import { motion } from "framer-motion";

const Industry = ({ title }) => {
  return (
    <motion.div
      className="flex flex-col items-center justify-center gap-5"
      initial={{ opacity: 0, scale: 0.5 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        whileHover={{ scale: 1.1 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        {title === "Maritime Trade" && (
          <GiCargoShip size={50} className="text-primary" />
        )}
        {title === "Port & Terminal Operations" && (
          <GiAnchor size={50} className="text-primary" />
        )}
        {title === "Logistics & Transport" && (
          <FaTruckFast size={50} className="text-primary" />
        )}
        {title === "Chemical Manufacturing" && (
          <FaFlaskVial size={50} className="text-primary" />
        )}
        {title === "Agriculture" && (
          <FaTractor size={50} className="text-primary" />
        )}
        {title === "Bearing Industry" && (
          <FaGears size={50} className="text-primary" />
        )}
        {title === "Lubricant Supply Chain" && (
          <FaTint size={50} className="text-primary" />
        )}
      </motion.div>

      <span className="text-center text-gray-500">{title}</span>
    </motion.div>
  );
};

export default Industry;
