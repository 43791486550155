import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 120) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <motion.button
      initial={{ y: 200 }}
      animate={{ y: isVisible ? 0 : 200 }}
      transition={{ duration: 0.8 }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={scrollToTop}
      title="Go To Top"
      className={`fixed z-50 flex justify-center text-lg font-semibold text-white transition-colors duration-300 bg-purple-600 border-0 rounded-lg shadow-md bottom-10 right-5 w-10 h-10 hover:bg-purple-700 ${
        isVisible ? "block" : "hidden"
      }`}
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
        </svg>
        <span className="sr-only">Go to top</span>
      </div>
    </motion.button>
  );
};

export default BackToTop;
