import React, { useEffect } from "react";
import "./Index.css";
import { Navbar } from "../Navbar";
import { HeroSection } from "../HeroSection";
import AnimatedLayout from "../Layouts/AnimatedLayout";
import FeaturesSection from "../FeaturesSection";
import BackToTop from "../Utils/BackToTop";
import { useLocation } from "react-router-dom";
import IndustriesSection from "../IndustriesSection";
import AboutSection from "../AboutSection";
import Footer from "../Footer";
import Chatbot from "../Utils/Chatbot";
import CookieConsentBanner from "../Utils/CookieConsentBanner";

function LandingPage() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <AnimatedLayout>
        <Navbar background={"bg-transparent"} />

        <div className="max-w-[1200px] my-0 mx-auto pt-10 px-[20px]">
          <HeroSection />
        </div>
        <div className="curve"></div>
      </AnimatedLayout>

      <div className="landing-container">
        <FeaturesSection />

        <IndustriesSection />

        <AboutSection />
      </div>

      <Footer />

      <Chatbot />
      
      <CookieConsentBanner />
      
      {/* <BackToTop /> */}
    </>
  );
}

export default LandingPage;
