import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RegistrationForm from "./components/RegistrationForm";
import "./App.css";
import LandingPage from "./components/LandingPage/Index";
import LegalNotices from './components/LegalNotices';
import ConditionsOfUse from './components/ConditionsOfUse';
import PrivacePolicy from './components/PrivacePolicy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/legal-notices" element={<LegalNotices />} />
        <Route path="/conditions-of-use" element={<ConditionsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacePolicy />} />
      </Routes>
    </Router>
  );
}

export default App;