import { useState, useEffect } from "react";

export const Navbar = ({background}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleContactClick = () => {
    setMobileMenuOpen(false);
    window.location.href = `mailto:contact@supplia.fr`;
  };

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShowHeader(false);
      } else {
        // if scroll up show the navbar
        setShowHeader(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div className="container">
      <nav
        className={`fixed top-0 w-full z-50 transition-transform duration-300 ${
          showHeader ? `translate-y-0 ${window.scrollY < 50 ? background : 'bg-[#34495e]'}` : "-translate-y-full"
        }`}
      >
        <div className="max-w-[1200px] px-[20px] my-0 mx-auto">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <button
                type="button"
                className="relative inline-flex items-center justify-center p-2 text-gray-400 rounded-md bg-none hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded={mobileMenuOpen}
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <span className="absolute -inset-0.5"></span>
                <span className="sr-only">Open main menu</span>
                {/* Icon when menu is closed */}
                <svg
                  className={`${mobileMenuOpen ? "hidden" : "block"} h-6 w-6`}
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
                {/* Icon when menu is open */}
                <svg
                  className={`${mobileMenuOpen ? "block" : "hidden"} h-6 w-6`}
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <a href="/">
              <img className="logo" src="logo.png" alt="logo" width={150} />
            </a>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="hidden sm:ml-6 sm:block">
                <a
                  href="/#"
                  className="px-3 py-2 text-sm font-medium text-white rounded-md"
                  aria-current="page"
                >
                  Home
                </a>
                <a
                  href="/#features"
                  className="px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white"
                >
                  Features
                </a>
                <a
                  href="/#industries"
                  className="px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white"
                >
                  Industries
                </a>
                <a
                  href="/#about"
                  className="px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white"
                >
                  About
                </a>
                <a
                  href="/#"
                  onClick={handleContactClick}
                  className="px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile menu */}
        {mobileMenuOpen && (
          <div className="sm:hidden bg-[#34495e]" id="mobile-menu">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <a
                href="/#"
                className="block px-3 py-2 text-base font-medium text-white bg-gray-900 rounded-md"
                aria-current="page"
                onClick={() => setMobileMenuOpen(false)}
              >
                Home
              </a>
              <a
                href="/#features"
                className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                Features
              </a>
              <a
                href="/#industries"
                className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                Industries
              </a>
              <a
                href="/#about"
                className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                About
              </a>
              <a
                href="/#contact"
                className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white"
                onClick={handleContactClick}
              >
                Contact Us
              </a>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};
