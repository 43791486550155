import { motion } from "framer-motion";

const ShootingStarButton = ({ title, onClick }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      onClick={onClick}
      className="group relative grid overflow-hidden rounded-full px-5 py-2.5 shadow-[0_1000px_0_0_hsl(0_0%_20%)_inset] transition-colors duration-150 text-center mb-2 animate-bounce hover:animate-none drop-shadow-2xl"
    >
      <span>
        <span className="spark mask-gradient animate-flip before:animate-rotate absolute inset-0 h-[100%] w-[100%] overflow-hidden rounded-full [mask:linear-gradient(white,_transparent_50%)] before:absolute before:aspect-square before:w-[200%] before:rotate-[-90deg] before:bg-[conic-gradient(from_0deg,transparent_0_340deg,white_360deg)] before:content-[''] before:[inset:0_auto_auto_50%] before:[translate:-50%_-15%]" />
      </span>
      <span className="backdrop absolute inset-[1px] rounded-full bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l transition-colors duration-200 focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800" />
      <span className="z-10 text">{title}</span>
    </motion.button>
  );
};

export default ShootingStarButton;
