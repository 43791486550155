import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';

const CookieConsentBanner = () => {
  return (
    <CookieConsent
      buttonText="Accept All"
      buttonStyle={{ background: "linear-gradient(#a855f7, #ec4899)", color: "white" }}
      style={{ display: "flex", alignItems: "center" }}
      overlay
    //   debug
    >
      <p className="text-white">
        We use cookies to improve your experience, provide analytics, and ensure
        compliance with data protection regulations. By clicking "Accept," you
        consent to all cookies. You can manage your preferences or withdraw
        consent at any time.
        <Link to="/privacy-policy" className="ml-1 underline text-primary">
    Privacy Policy
  </Link>
        .
      </p>{" "}
    </CookieConsent>
  );
};

export default CookieConsentBanner;
