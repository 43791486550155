import React from "react";

const AnimatedLayout = (props) => {
  return (
    <div className="animated-background h-full bg-gradient-to-r from-[#8e44ad] via-blue-900 to-[#3a0053]">
      <div className="star"></div>
      <div className="star"></div>
      <div className="star"></div>
      <div className="star"></div>
      <div className="star"></div>
      <div className="star"></div>
      <div className="star"></div>
      <div className="star"></div>
      <div className="star"></div>
      <div>{props.children}</div>
    </div>
  );
};

export default AnimatedLayout;
