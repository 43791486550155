import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAop4IhxbJT3z8OtPNIJMFfOD2-ptMHd4s",
  authDomain: "lyde-supplia-beta.firebaseapp.com",
  projectId: "lyde-supplia-beta",
  storageBucket: "lyde-supplia-beta.appspot.com",
  messagingSenderId: "1050132265276",
  appId: "1:1050132265276:web:12a8e20a9593ccd6130639",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };