import React, { useEffect, useState } from "react";

const WorldMap = () => {
  const [activeItem, setActiveItem] = useState(null);

  const worldMapPositions = [
    { top: 35, left: 69, country: "Estonia" }, // Estonia
    { top: 39, left: 67, country: "Latvia" }, // Latvia
    { top: 43, left: 65, country: "Lithuania" }, // Lithuania
    { top: 47, left: 72, country: "Belarus" }, // Belarus
    { top: 57, left: 76, country: "Ukraine" }, // Ukraine
    { top: 69, left: 67, country: "Bulgaria" }, // Bulgaria
    { top: 63, left: 67, country: "Romania" }, // Romania
    { top: 61, left: 59, country: "Hungary" }, // Hungary
    { top: 57, left: 60, country: "Slovakia" }, // Slovakia
    { top: 50, left: 61, country: "Poland" }, // Poland
    { top: 60, left: 54, country: "Austria" }, // Austria
    { top: 67, left: 51, country: "Italy" }, // Italy
    { top: 61, left: 42, country: "Switzerland" }, // Switzerland
    { top: 73, left: 22, country: "Portugal" }, // Portugal
    { top: 73, left: 30, country: "Spain" }, // Spain
    { top: 60, left: 37, country: "France" }, // France
    { top: 54, left: 40, country: "Belgium" }, // Belgium
    { top: 49, left: 38, country: "Netherlands" }, // Netherlands
    { top: 51, left: 48, country: "Germany" }, // Germany
    { top: 41, left: 46, country: "Denmark" }, // Denmark
    { top: 49, left: 26, country: "United Kingdom" }, // United Kingdom
    { top: 48, left: 23, country: "Ireland" }, // Ireland
    { top: 21, left: 70, country: "Finland" }, // Finland
    { top: 27, left: 47, country: "Norway" }, // Norway
    { top: 17, left: 57, country: "Sweden" }, // Sweden
    { top: 17, left: 9, country: "Iceland" }, // Iceland
    { top: 72, left: 60, country: "Albania" }, // Albania
    { top: 55, left: 49, country: "Czech Republic" }, // Czech Republic
    { top: 61, left: 72, country: "Moldova" }, // Moldova
    { top: 67, left: 63, country: "Serbia" }, // Serbia
    { top: 75, left: 64, country: "Greece" }, // Greece
  ];

  const [positions, setPositions] = useState(worldMapPositions);

  const getRandomWorldMapPosition = () => {
    return worldMapPositions[
      Math.floor(Math.random() * worldMapPositions.length)
    ];
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * positions.length);
      const newPosition = getRandomWorldMapPosition();

      setActiveItem(randomIndex);

      setPositions((prevPositions) =>
        prevPositions.map((item, index) =>
          index === randomIndex ? { ...item, ...newPosition } : item
        )
      );

      const timeoutVisible = setTimeout(() => {
        setActiveItem(randomIndex);
      }, 500);

      const timeoutHide = setTimeout(() => {
        setActiveItem(null);
      }, 3200);

      return () => {
        clearTimeout(timeoutVisible);
        clearTimeout(timeoutHide);
      };
    }, 4000);

    return () => clearInterval(interval);
  }, [positions]);

  return (
    <div className="animated-world-map primary">
      <img src="europe-map.png" alt="Map" />
      {positions.map((pos, index) => (
        <div
          key={index}
          className={`animated-world-map__item animated-world-map__item--${
            index + 1
          } ${activeItem === index ? "visible" : ""}`}
          style={{ top: `${pos.top}%`, left: `${pos.left}%` }}
        >
          {pos.country}
        </div>
      ))}
    </div>
  );
};

export default WorldMap;
