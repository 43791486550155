import React from "react";
import { motion } from "framer-motion";
import AnimationTitles from "./Utils/AnimationTitles";
import WorldMap from "./Utils/WorldMap";
import ShootingStarButton from "./Utils/ShootingStarButton";

export const HeroSection = () => {
  const handleJoinWaitlist = () => {
    const subject = encodeURIComponent("I want early access to revolutionize my supply chain!");
    const body = encodeURIComponent("Hi Supplia team,\n\nI’m eager to be among the first to experience your innovative platform! Please add me to your waiting list so I can stay updated and be one of the first to transform our supply chain operations.\n\nBest regards,");
    window.location.href = `mailto:contact@supplia.fr?subject=${subject}&body=${body}`;
  };

  return (
    <header className="grid items-center mt-20 lg:grid-cols-2 hero lg:mt-0">
      <motion.div
        initial={{ x: -200 }}
        whileInView={{ x: 0 }}
        transition={{ duration: 0.8 }}
      >
        <AnimationTitles title={"Revolutionize Your Supply Chain"} />
        <p>AI-Driven Solutions for Seamless Operations</p>

        <ShootingStarButton
          onClick={handleJoinWaitlist}
          title={"Join Waiting List"}
        />
      </motion.div>

      <motion.div
        transition={{ duration: 0.8 }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="flex justify-center"
      >
        <WorldMap />
      </motion.div>
    </header>
  );
};
