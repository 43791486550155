import React from "react";
import BasicLayout from "./Layouts/BasicLayout";

const LegalNotices = () => {
  return (
    <BasicLayout>
      <div className="max-w-4xl p-6 mx-auto space-y-10">
        <h1 className="text-3xl font-bold">Legal Notices</h1>
        <div>
        <p className="mb-2 italic font-semibold">
            Last updated: October 2, 2024
          </p>

          <div className="mb-8 space-y-5"></div>
          <p>
            In accordance with the provisions of Law No. 2004-575 of 21 June
            2004 for confidence in the digital economy{" "}
            <i>(Loi pour la confiance dans l'économie numérique)</i>, users of
            the{" "}
            <a href="https://www.supplia.fr" className="underline text-primary">
              https://www.supplia.fr
            </a>{" "}
            site are informed of the identity of the various stakeholders
            involved in its implementation and monitoring.
          </p>
         
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-semibold">
            1. Website Publisher (Éditeur du site)
          </h3>
          <p>
            The edition of the application called <b>“Lyde Supplía"</b> is provided by
            the Company <b>SAS Éclair Universel Société de développement de
            logiciels (EUSDL)</b>.{" "}
          </p>
        </div>
        <div className="space-y-1 ms-5">
          <li>
            <span className="font-semibold">Company Name:</span> Éclair
            Universel Société de développement de logiciels (EUSDL)
          </li>
          <li>
            <span className="font-semibold">Legal Form:</span> SAS
          </li>
          <li>
            <span className="font-semibold">Contact Information:</span>{" "}
            <span>
              Email: <a href="mailto:legal@supplia.eu" className="text-blue-600">legal@supplia.eu</a>
            </span>
          </li>
          <li>
            <span className="font-semibold">Publication Director:</span> Partho
            CHOUDHURY
          </li>
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-semibold">
            2. Website Host (Hébergeur du site)
          </h3>
          <p>
            The host of the site called <b>"Lyde Supplía"</b> is OVHcloud (OVH SAS),
            which is a subsidiary of OVH Groupe SAS, a SAS with capital of
            10,069,020 euros, registered in the Lille Métropole Trade and
            Companies Registered under number 537 407 926, with its registered
            office at 2, rue Kellermann, BP 80157, 59100 Roubaix.
          </p>
        </div>
        <div className="space-y-1 ms-5">
          <li>
            <span className="font-semibold">Company Name:</span> OVHcloud (OVH
            SAS)
          </li>
          <li>
            <span className="font-semibold">Address:</span>{" "}
            <span>2, rue Kellermann, BP 80157, 59100 Roubaix</span>
          </li>
          <li>
            <span className="font-semibold">Location of the Datacenter:</span>{" "}
            Gravelines 1 – OVHcloud. Gravelines 1 is one of the flagship data
            centers operated by OVHcloud, located in Gravelines, Northern
            France.
          </li>
          <li>
            <span className="font-semibold">Telephone:</span>{" "}
            <a href="tel:0972101007">09 72 10 10 07</a>
          </li>
          <li>
            <span className="font-semibold">Email:</span>{" "}
            <a href="mailto:support@ovh.com" className="text-blue-600">support@ovh.com</a>
          </li>
          <li>
            <span className="font-semibold">Website:</span>{" "}
            <a
              href="https://www.ovhcloud.com/fr/"
              className="underline text-primary"
            >
              https://www.ovhcloud.com/fr/
            </a>
          </li>
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-semibold">3. Design and Development</h3>
          <div className="space-y-1 ms-5">
            <li>
              <span className="font-semibold">Designer/Developer:</span> Partho
              CHOUDHURY (Team)
            </li>
            <li>
              <span className="font-semibold">Address:</span> STATION F, 5 Parv.
              Alan Turing, 75013 Paris
            </li>
            <li>
              <span className="font-semibold">Email:</span>{" "}
              <a href="mailto:support@ovh.com" className="text-blue-600">contact@supplia.fr</a>
            </li>
          </div>
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-semibold">4. Data Protection </h3>
          <li className="ms-5">
            <span className="font-semibold">
              Data Protection Officer (DPO):
            </span>{" "}
            <a href="mailto: dpo@supplia.eu" className="text-blue-600"> dpo@supplia.eu</a>
          </li>
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-semibold">5. Intellectual Property</h3>
          <p>
            All content on the{" "}
            <a href="https://www.supplia.fr" className="underline text-primary">
              https://www.supplia.fr
            </a>{" "}
            site, including texts, images, graphics, logos, icons, and sounds,
            is the property of{" "}
            <i>
              SAS Éclair Universel Société de développement de logiciels (EUSDL)
              or “Lyde Supplía”
            </i>{" "}
            or third parties who have authorized its use. Any reproduction,
            representation, modification, publication, or adaptation of all or
            part of the site's elements, regardless of the means or process
            used, is prohibited without prior written permission
          </p>
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-semibold">6. Limitation of Liability</h3>
          <p>
            <i>
              SAS Éclair Universel Société de développement de logiciels (EUSDL)
              or “Lyde Supplía”
            </i>
            , including its various stakeholders cannot be held responsible for
            direct or indirect damage to the user's equipment when accessing the{" "}
            <a href="https://www.supplia.fr" className="underline text-primary">
              https://www.supplia.fr
            </a>{" "}
            site, resulting either from the use of equipment that does not meet
            the specified requirements or from the appearance of a bug or
            incompatibility.
          </p>
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-semibold">
            7. Personal Data Management
          </h3>
          <p>
            Your privacy is of utmost importance to us. The processing of your
            personal data is governed by our Privacy Charter, available from the
            "Personal Data Protection Charter" section, in accordance with the
            General Data Protection Regulation 2016/679 of 27 April 2016
            (“GDPR").
          </p>
        </div>
        <p>
          Regulation (EU) 2016/679 of the European Parliament and of the Council
          of 27 April 2016 on the protection of natural persons with regard to
          the processing of personal data and on the free movement of such data,
          and repealing Directive 95/46/EC (General Data Protection Regulation).
        </p>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">A. Data Controller</h3>
          <div className="space-y-1 ms-5">
            <li>
              <span className="font-semibold">Company Name:</span> SAS Éclair
              Universel Société de développement de logiciels (EUSDL)
            </li>
            <li>
              <span className="font-semibold">Address:</span> 50 Avenue des
              Champs-Élysées 75008 Paris
            </li>
            <li>
              <span className="font-semibold">Contact Information:</span>
              <div className="space-y-1 ms-10">
                <div>
                  <span className="font-semibold">Email:</span>{" "}
                  <a href="mailto:contact@supplia.fr" className="text-blue-600">contact@supplia.fr</a>
                </div>
                <div>
                  <span className="font-semibold">Telephone:</span>{" "}
                  <a href="tel:+33685683593">+33 6 85 68 35 93</a>
                </div>
              </div>
            </li>
          </div>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">
            B. Types of Personal Data Collected
          </h3>
          <p>We may collect and process the following personal data:</p>
          <div className="space-y-1 ms-5">
            <li>
              <span className="font-semibold">Identity Information:</span> Name,
              surname, date of birth.
            </li>
            <li>
              <span className="font-semibold">Contact Information:</span> Email
              address, telephone number, postal address.
            </li>
            <li>
              <span className="font-semibold">Usage Data:</span> Information on
              how you use our website and services.
            </li>
            <li>
              <span className="font-semibold">Technical Data:</span> IP address,
              browser type, operating system, and other technical details.
            </li>
            <li>
              <span className="font-semibold">
                Cookies and Tracking Technologies:
              </span>{" "}
              Data collected through cookies and similar technologies.
            </li>
          </div>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">
            C. Purposes of Data Processing
          </h3>
          <p>
            Your personal data is collected and processed for the following
            purposes:
          </p>
          <div className="space-y-1 ms-5">
            <li>To provide and maintain our services.</li>
            <li>To manage your account and orders.</li>
            <li>To personalize your experience on our website.</li>
            <li>
              To communicate with you regarding updates, offers, and promotions.
            </li>
            <li>To comply with legal and regulatory obligations.</li>
            <li>
              To improve our website, products, and services through analysis
              and research.
            </li>
          </div>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">
            D. Legal Basis for Processing
          </h3>
          <p>
            We process your personal data based on one or more of the following
            legal grounds:
          </p>
          <div className="space-y-1 ms-5">
            <li>
              <span className="font-semibold">Consent:</span> You have given
              clear consent for us to process your personal data for specific
              purposes.
            </li>
            <li>
              <span className="font-semibold">Contractual Necessity:</span>{" "}
              Processing is necessary for the performance of a contract to which
              you are a party.
            </li>
            <li>
              <span className="font-semibold">Legal Obligation:</span>{" "}
              Processing is necessary for compliance with a legal obligation to
              which we are subject.
            </li>
            <li>
              <span className="font-semibold">Legitimate Interests:</span>{" "}
              Processing is necessary for our legitimate interests, provided
              that your interests and fundamental rights do not override those
              interests.
            </li>
          </div>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">E. Data Retention</h3>
          <p>
            We retain your personal data only for as long as necessary to
            fulfill the purposes for which it was collected, including for
            satisfying any legal, accounting, or reporting requirements
          </p>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">F. Data Security</h3>
          <p>
            We implement appropriate technical and organizational measures to
            protect your personal data against unauthorized access, alteration,
            disclosure, or destruction. These measures include:
          </p>
          <div className="space-y-1 ms-5">
            <li>Secure servers and firewalls.</li>
            <li>Encryption of data.</li>
            <li>Regular security assessments.</li>
          </div>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">G. Your Rights Under GDPR</h3>
          <p>
            Under the GDPR, you have the following rights regarding your
            personal data:
          </p>
          <div className="space-y-1 ms-5">
            <li>
              <span className="font-semibold">Right of Access:</span> Obtain
              confirmation as to whether or not your personal data is being
              processed, and access your personal data.
            </li>
            <li>
              <span className="font-semibold">Right to Rectification:</span>{" "}
              Request correction of inaccurate personal data.
            </li>
            <li>
              <span className="font-semibold">Right to Erasure:</span> Request
              deletion of your personal data under certain conditions.
            </li>
            <li>
              <span className="font-semibold">
                Right to Restrict Processing:
              </span>{" "}
              Request limitation of the processing of your personal data.
            </li>
            <li>
              <span className="font-semibold">Right to Data Portability:</span>{" "}
              Receive your personal data in a structured, commonly used format
              and have it transmitted to another controller.
            </li>
            <li>
              <span className="font-semibold">Right to Object:</span> Object to
              the processing of your personal data for certain purposes.
            </li>
            <li>
              <span className="font-semibold">Right to Withdraw Consent:</span>{" "}
              Withdraw your consent at any time where we rely on consent to
              process your data.
            </li>
            <p>
              To exercise any of these rights, please contact us at:{" "}
              <a href="mailto:dpo@supplia.eu" className="text-blue-600">dpo@supplia.eu</a>.
            </p>
          </div>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">H. Data Transfers</h3>
          <p>
            Your personal data may be transferred to and processed in countries
            outside the European Economic Area (EEA). We ensure that appropriate
            safeguards are in place to protect your data in accordance with GDPR
            requirements.
          </p>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">I. Disclosure of Data</h3>
          <p>
            We may disclose your personal data to third parties only in the
            following situations:
          </p>
          <div className="space-y-1 ms-5">
            <li>To service providers who assist in our business operations.</li>
            <li>
              To comply with legal obligations or respond to lawful requests
              from public authorities.
            </li>
            <li>To protect and defend our rights and property.</li>
          </div>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">
            J. Cookies and Similar Technologies
          </h3>
          <p>
            The{" "}
            <a href="https://www.supplia.fr" className="underline text-primary">
              https://www.supplia.fr
            </a>{" "}
            site may use cookies to enhance the user experience. A cookie is a
            small data file stored on the user's hard drive by the website.
            Users can configure their browser to refuse cookies.
          </p>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">K. Third-Party Websites</h3>
          <p>
            Our website may contain links to external sites not operated by us.
            We have no control over the content and practices of these sites and
            cannot accept responsibility for their respective privacy policies.
          </p>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">
            L. Changes to This Privacy Charter
          </h3>
          <p>
            We may update our Privacy Charter from time to time. Any changes
            will be posted on this page with an updated revision date. We
            encourage you to review this Charter periodically.
          </p>
        </div>
        <div className="space-y-2 ms-5">
          <h3 className="text-2xl font-semibold">M. Contact Information</h3>
          <p>
            If you have any questions or concerns about this Privacy Charter or
            our data processing practices, please contact us:
          </p>
          <div className="space-y-1 ms-5">
            <li>
              <span className="font-semibold">Email:</span>{" "}
              <a href="mailto:dpo@supplia.eu" className="text-blue-600">dpo@supplia.eu</a>
            </li>
            <li>
              <span className="font-semibold">Postal Address:</span> 50 Avenue
              des Champs-Élysées 75008 Paris
            </li>
            <li>
              <span className="font-semibold">Telephone:</span>{" "}
              <a href="tel:+33685683593">+33 6 85 68 35 93</a>
            </li>
          </div>
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-semibold">8. Hyperlinks</h3>
          <p>
            The{" "}
            <a href="https://www.supplia.fr" className="underline text-primary">
              https://www.supplia.fr
            </a>{" "}
            site may contain hyperlinks to other sites. However,{" "}
            <i>
              SAS Éclair Universel Société de développement de logiciels (EUSDL)
              or “Lyde Supplía”
            </i>{" "}
            does not have control over these external sites and therefore
            assumes no responsibility for their content.
          </p>
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-semibold">
            9. Applicable Law and Jurisdiction
          </h3>
          <p>
            Any dispute related to the use of the{" "}
            <a href="https://www.supplia.fr" className="underline text-primary">
              https://www.supplia.fr
            </a>{" "}
            site is subject to French law. Exclusive jurisdiction is granted to
            the competent courts of the Paris Court of Appeal.
          </p>
        </div>
      </div>
    </BasicLayout>
  );
};

export default LegalNotices;
