import React from "react";
import BasicLayout from "./Layouts/BasicLayout";

const ConditionsOfUse = () => {
  return (
    <BasicLayout>
      <div className="max-w-4xl p-6 mx-auto space-y-10">
        {/* Title */}
        <h1 className="mb-4 text-3xl font-bold">
          Conditions of Use
        </h1>
        <p className="mb-2 italic font-semibold">
          Last updated: October 2, 2024
        </p>

        <div className="mb-8 space-y-5">
          <p>
            The purpose of these general conditions of use (known as "GCU") is
            to regulate the legal framework for the terms of provision of the
            application and services by <b>Lyde Supplía</b> ("we," "us," or
            "our"), and to define the conditions of access and use of the
            services by the "User."{" "}
          </p>
          <p>These GCU are accessible on our website in the footer section.</p>
          <p>
            Any registration or use of our services implies acceptance without
            any reservation or restriction of these GCU by the User. When
            registering on the waiting list or interacting with our services,
            each user expressly accepts these GCU. In case of non-acceptance,
            the User must refrain from accessing the services offered.{" "}
          </p>
          <p>
            Lyde Supplía reserves the right to unilaterally modify these GCU at
            any time, and users will be informed of significant changes
            accordingly.{" "}
          </p>
        </div>


        {/* Sections */}
        <div className="space-y-10">
          {/* Section 1: Access to Website and Services */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              ARTICLE 1: ACCESS TO THE WEBSITE AND SERVICES
            </h3>
            <div className="space-y-5">
              <p className="text-gray-800">
                The Lyde Supplía platform offers Users early access to supply
                chain visibility and automation solutions, aiming to simplify
                logistics and optimize operations for European businesses.
                Currently, the website serves as an introduction to the
                platform, enabling users to sign up for our waiting list for
                future access and explore summarized features.
              </p>
              <p className="text-gray-800">
                Our website is accessible free of charge anywhere to Users with
                an Internet connection. All associated costs (hardware,
                software, Internet connection) are the responsibility of the
                User.
              </p>
              <p className="text-gray-800">
                Only users who sign up for the waiting list can receive direct
                updates and access reserved services, such as early testing and
                platform access.
              </p>
              <p className="text-gray-800">
                The User is responsible for the security of their account
                credentials and must ensure confidentiality. If issues arise,
                the User may contact our support team at: {""}
                <a href="mailto:contact@supplia.fr" className="text-blue-600">
                  contact@supplia.fr
                </a>
                .
              </p>
            </div>
          </section>

          {/* Section 2: Data Collection */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              ARTICLE 2: DATA COLLECTION
            </h3>
            <div className="space-y-5">
              <p className="text-gray-800">
                Lyde Supplía ensures the collection and processing of personal
                information, complies with privacy regulations, including
                France's Data Protection Act of January 6, 1978, and the General
                Data Protection Regulation (GDPR).
              </p>
              <p className="text-gray-800">
                Personal data collected during waiting list registration or
                interactions on the website is securely handled. Users can
                exercise their rights to access, rectify, delete, or oppose
                their personal data by contacting:{" "}
                <a href="mailto:dpo@supplia.eu" className="text-blue-600">
                  dpo@supplia.eu
                </a>
                . For further details, consult our Privacy Policy.
              </p>
            </div>
          </section>

          {/* Section 3: Intellectual Property */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              ARTICLE 3: INTELLECTUAL PROPERTY
            </h3>

            

            <div className="space-y-5">
              <p className="text-gray-800">
                The trademarks, logos, and content (texts, images, designs) on
                the Lyde Supplía website are protected under the French
                Intellectual Property Code.
              </p>
              <div className="space-y-1 ms-5">
          <li>
            <span className="font-semibold">Lyde Supplía</span> is the registered trademark and official brand signature of our company.
          </li>
          <li>The name{" "}
            <span className="font-semibold">Lyde Supplia</span>{" "}
            <span>(without the accent) may appear for convenience in some contexts. 
However, both names refer to the same protected brand and should not be considered separately or used independently.</span>
          </li>
          <li>Unauthorized use or misrepresentation of the{" "}
            <span className="font-semibold">Lyde Supplía</span>{" "}
           brand, in any form, is strictly prohibited and may lead to legal consequences.
          </li>
        </div>
              <p className="text-gray-800">
                The <b>Lyde Supplía</b> brand is a registered trademark of{" "}
                <b>
                  SAS Éclair Universel Société de développement de logiciels
                  (EUSDL)
                </b>
                . Any reproduction or exploitation of this trademark without
                explicit consent is strictly prohibited.
              </p>
              <p className="text-gray-800">
                Users must request prior authorization to use, reproduce, or
                distribute any material from the Lyde Supplía website. The
                content is intended for personal use only, and any commercial or
                public use without permission is forbidden.
              </p>
            </div>
          </section>

          {/* Section 4: Responsibility */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              ARTICLE 4: RESPONSIBILITY
            </h3>
            <div className="space-y-5">
              <p className="text-gray-800">
                The information provided on the Lyde Supplía website is
                regularly updated to ensure accuracy, but we cannot guarantee
                that it is entirely free of errors or omissions. Lyde Supplía
                will not be held responsible for any direct or indirect damages
                arising from the use or inability to access the website, nor for
                any external factors (such as force majeure) affecting service
                continuity.
              </p>
              <p className="text-gray-800">
                Users are advised to take the necessary precautions regarding
                their security, including maintaining the confidentiality of
                their login credentials.
              </p>
            </div>
          </section>

          {/* Section 5: Hypertext Links */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              ARTICLE 5: HYPERTEXT LINKS
            </h3>
            <p className="text-gray-800">
              The Lyde Supplía website may contain links to third-party
              websites. Lyde Supplía has no control over the content of such
              sites and cannot be held responsible for any content, updates, or
              issues arising from accessing these external links.
            </p>
          </section>

          {/* Section 6: User Contributions and Interactions */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              ARTICLE 6: USER CONTRIBUTIONS AND INTERACTIONS
            </h3>
            <div className="space-y-5">
              <p className="text-gray-800">
                At this stage, the Lyde Supplía website focuses on providing
                concise information about our upcoming product and allows Users
                to interact through a chatbot, designed to answer general
                inquiries without disclosing sensitive or detailed information.
              </p>
              <p className="text-gray-800">
                Users are encouraged to respect netiquette and the relevant
                legal guidelines when engaging with Lyde Supplía or interacting
                with any public forums or comment sections associated with the
                brand.
              </p>
            </div>
          </section>

          {/* Section 7: Applicable Law and Jurisdiction */}
          <section>
            <h3 className="mb-2 text-2xl font-semibold">
              ARTICLE 7: APPLICABLE LAW AND JURISDICTION
            </h3>
            <div className="space-y-5">
              <p className="text-gray-800">
                French law governs these GCU. In the event of disputes that
                cannot be resolved amicably, French courts will have sole
                jurisdiction to resolve the matter.
              </p>
              <p className="text-gray-800">
                For any inquiries related to these conditions of use, please
                contact us at:{" "}
                <a href="mailto:contact@supplia.fr" className="text-blue-600">
                  contact@supplia.fr
                </a>
                .
              </p>
            </div>
          </section>
          <p>
            This policy reflects Lyde Supplía's commitment to protecting user
            privacy and ensuring compliance with relevant legal frameworks. We
            encourage users to stay informed of updates as the platform evolves.{" "}
          </p>
        </div>
      </div>
    </BasicLayout>
  );
};

export default ConditionsOfUse;
