import React, { useState } from 'react';
import { db } from '../firebase'; // Firebase configuration
import './RegistrationForm.css';

function RegistrationForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // Save data to Firebase
    db.collection('betaTesters').add(formData)
      .then(() => {
        alert('Registration Successful!');
        setFormData({ name: '', email: '', company: '' });
      })
      .catch((error) => {
        alert('Error:', error.message);
      });
  };

  return (
    <div className="form-container">
      <h2>Beta Tester Registration</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Full Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={formData.email}
          onChange={handleChange}
          required
        />
        
        <input
          type="text"
          name="company"
          placeholder="Company Name"
          value={formData.company}
          onChange={handleChange}
        />
        
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default RegistrationForm;
